<template>
  <TableLayout class="menu-layout" :permissions="['system:com:passInfo']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场名称" prop="passName">
        <el-input v-model="searchForm.comName" v-trim placeholder="请输入车场名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="车场编号" prop="passName">
        <el-input v-model="searchForm.comId" v-trim placeholder="请输入车场名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="通道名称" prop="passName">
        <el-input v-model="searchForm.passName" v-trim placeholder="请输入车场名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="通道类型">
        <el-select v-model="searchForm.passType" placeholder="请选择车场类型">
          <el-option label="入" value="0"></el-option>
          <el-option label="出" value="1"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
        <el-button type="success" @click="clearCall" v-permissions="['system:clear:call']">一键清除通道通话异常状态</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="passname" label="通道名称" min-width="200px"></el-table-column>
        <el-table-column prop="passType" label="通道类型" min-width="80px">
          <template slot-scope="{row}">{{row.passtype == '0' ? '入' : '出'}}</template>
        </el-table-column>
        <el-table-column prop="comName" label="车场名称" min-width="100px"></el-table-column>
        <el-table-column prop="comid" label="车场编号" min-width="100px"></el-table-column>
        <el-table-column prop="description" label="说明" min-width="100px"></el-table-column>
        <el-table-column
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-s-tools" @click="setQCore(row)">生成二维码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

    <el-dialog title="二维码预览" id="dialogcode" :visible.sync="dialogcode" width="240px" center :modal="false" top="30vh" style="z-index:3000" >
       <div style="text-align:center;">  
          <div id="qrcode" ref="qrcode"></div>
          <el-button type="primary" @click="upDown" size="small" style="margin-top:20px;">下载二维码</el-button>
        </div>
      <!-- <span slot="footer" class="dialog-footer">
       
      </span> -->
    </el-dialog>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import QRCode from "qrcodejs2";
import {clearCall} from "@/api/parking/pass"
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      // 搜索时间范围
      searchDateRange: [],
      // 
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        passName:'',
        passType:'',
        comId:"",
        comName:""
      },
      dialogcode:false
    }
  },
  methods: {
    //生成通道对讲二维码
    setQCore(row){
      this.dialogcode = true;
      setTimeout(()=>{
        this.$refs.qrcode.innerHTML='';
        new QRCode("qrcode", {
          width:150,
          height:150, // 高度
          correctLevel: 3,
          // text:'https://y.ist.wang/yunying/scanf/' + row.id, // 二维码内容
          text:'https://test22.sciseetech.com/yunying/scanf/' + row.id, // 二维码内容
          // text:'https://yunying-beta.sciseetech.com/yunying/scanf/' + row.id, // 二维码内容
          render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          background: "#f0f",
          foreground: "#ff0"
        });
      })
    },
    // 下载二维码
    upDown() {
      // //找到canvas标签
      let myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      let img = document.getElementById("qrcode").getElementsByTagName("img");
      //创建一个a标签节点
      let a = document.createElement("a");
      //设置a标签的href属性（将canvas变成png图片）
      let imgURL = myCanvas[0].toDataURL("image/jpg");
      let ua = navigator.userAgent;
      if (ua.indexOf("Trident") != -1 && ua.indexOf("Windows") != -1) {
        // IE内核 并且  windows系统 情况下 才执行;
        var bstr = atob(imgURL.split(",")[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "下载" + "." + "png");
      } else if (ua.indexOf("Firefox") > -1) {
        //火狐兼容下载
        let blob = this.base64ToBlob(imgURL);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        a.download = "二维码"; //下载图片名称，如果填内容识别不到，下载为未知文件
        a.href = URL.createObjectURL(blob);
        a.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
          })
        ); //兼容火狐
      } else {
        //谷歌兼容下载
        img.src = myCanvas[0].toDataURL("image/jpg");
        a.href = img.src;
        //设置下载文件的名字
        a.download = "二维码";
        //点击
        a.click();
      }
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.createTimeStart = null
      this.searchForm.createTimeEnd = null
      if (value != null) {
        this.searchForm.createTimeStart = value[0] / 1000
        this.searchForm.createTimeEnd = value[1] / 1000
      }
      this.search()
    },
    //重置
    resetNew(){
      this.searchForm = {
        passName:'',
        passType:''
      }
      this.search()
    },
    //清除通道通话异常状态
    clearCall(){
      clearCall().then(res=>{
        this.$message({
          showClose: true,
          message: '清除成功',
          type: "success",
        });
      })
    }
  },
  created () {
    this.config({
      module: '菜单',
      api: '/parking/pass'
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
#qrcode{
  margin:20px;
  margin-top:0;
}
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
